type ILocalStorageKey =
  | 'logged_in_user'
  | 'tours-page-tab'
  | 'tours-page-filters';

export class LocalStorageUtils {
    static getItem<T = string>(
        key: ILocalStorageKey,
        options?: {
            /**
             * Value will be parsed as JSON if this is true.
             */
            isJson?: boolean,
            /**
             * Merge the value with this object. Only works if `isJson` is true.
             */
            mergeWith?: T,
        }
    ): T | null {
        const value = localStorage.getItem(key);

        const { isJson, mergeWith } = options || {};

        if (!value) { return null; }

        if (!isJson) {
            return value as T | null;
        }

        const parsedValue = JSON.parse(value) as T;

        if (mergeWith && typeof mergeWith === 'object') {
            return { ...mergeWith, ...parsedValue };
        } else {
            return parsedValue;
        }
    }

    static setItem(key: ILocalStorageKey, value: string | null) {
        if (value === null) {
            localStorage.removeItem(key);
        } else {
            localStorage.setItem(key, value);
        }
    }
}