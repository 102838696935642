import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from 'src/App';
import AppStateProvider from 'src/AppStateProvider';
import { NotificationsProvider } from 'src/notifications';
import analyticsClient, { AnalyticsEvent } from 'src/services/analytics';
import type { LoginResponse } from 'src/services/api';
import { ApiServiceProvider } from 'src/services/api';
import Auth from 'src/services/api/auth/Auth';
import { simpleKy } from 'src/services/api/ky';
import { ThemeProvider } from 'src/theme';

import 'src/index.css';

/**
 * Check if the user has a one time token in the URL and if so, exchange it for an access token.
 * This is used when we want to log in as an existing user via the "login as" feature in the admin panel.
 */
const checkForOneTimeToken = async () => {
    console.debug('Checking for one time token (for delegated login)...');
    const urlParams = new URLSearchParams(window.location.search);
    const oneTimeToken = urlParams.get('oneTimeToken');

    if (oneTimeToken) {
        const res = await simpleKy('api/auth/landlord/one-time-token-login', {
            method: 'post',
            json: { token: oneTimeToken },
        }).json<LoginResponse>();

        Auth.saveLoginInfo(res);

        location.replace('/landlord/inner/messages');
    } else {
        console.debug('No one time token found.');
    }
};

checkForOneTimeToken();

// check if we are on the old version of the site
const url = new URL(window.location.href);
if (url.hostname === 'beta.reffie.me') {
    analyticsClient.track(AnalyticsEvent.REDITECTED_FROM_BETA_TO_PLATFORM);
    window.location.replace('https://platform.reffie.me');
}

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <ApiServiceProvider>
                <AppStateProvider>
                    <ThemeProvider>
                        <NotificationsProvider>
                            <App />
                        </NotificationsProvider>
                    </ThemeProvider>
                </AppStateProvider>
            </ApiServiceProvider>
        </BrowserRouter>
    </React.StrictMode>
);
