import { isStandardErrorResponse } from 'src/common/api-utils';
import Auth from 'src/services/api/auth/Auth';
import { simpleKy } from 'src/services/api/ky/simple-ky';

/**
 * Extends our simple ky instance with hooks and custom headers.
 *
 * 1. Before each request, it sets the auth headers
 * 2. After each response, we redirect the user to the login page if we receive 401 or 422
 */
export const extendedKy = simpleKy.extend({
    hooks: {
        beforeRequest: [
            (request) => {
                const accessToken = Auth.accessToken;
                const authHeaders = Auth.getAuthHeaders(accessToken);

                if (!authHeaders) {
                    Auth.redirectToLoginPage();
                    return;
                }

                for (const [key, value] of Object.entries(authHeaders)) {
                    request.headers.set(key, value);
                }
            },
        ],
        afterResponse: [
            async (_request, _options, response) => {
                if ([401, 422].includes(response.status)) {
                    Auth.redirectToLoginPage();
                } else if (!response.ok) {
                    const error = await response.json();
                    if (isStandardErrorResponse(error)) {
                        throw error;
                    } else {
                        throw {
                            status: 'error',
                            msg: 'An unknown error occurred',
                        };
                    }
                }
            },
        ],
    },
});
