import React from 'react';
import CreditCardIcon from '@mui/icons-material/CreditCard';

// import {
//     AmericanExpressIcon,
//     DinersClubIcon,
//     MasterCardIcon,
//     VisaIcon
// } from 'src/icons';
import { type IBillingInfo } from 'src/services/api/billing-info';

export enum EPaymentMethodType {
    AMEX = 'amex',
    VISA = 'visa',
    MASTERCARD = 'mastercard',
    DISCOVER = 'discover',
    DINERS_CLUB = 'diners_club',
}

export const paymentMethodTypeLabels = {
    [EPaymentMethodType.AMEX]: 'American Express',
    [EPaymentMethodType.VISA]: 'Visa',
    [EPaymentMethodType.MASTERCARD]: 'Mastercard',
    [EPaymentMethodType.DISCOVER]: 'Discover',
    [EPaymentMethodType.DINERS_CLUB]: 'Diners Club',
};

export const getPaymentMethodIcon = (
    paymentMethod: NonNullable<IBillingInfo['subscription']>['payment_methods'][0]
) => {
    // if (!type) {
    //     return <CreditCardIcon />;
    // }

    // switch (type) {
    //     case EPaymentMethodType.AMEX:
    //         return <AmericanExpressIcon />;
    //     case EPaymentMethodType.DINERS_CLUB:
    //         return <DinersClubIcon />;
    //     case EPaymentMethodType.DISCOVER:
    //         return <CreditCardIcon />;
    //     case EPaymentMethodType.MASTERCARD:
    //         return <MasterCardIcon />;
    //     case EPaymentMethodType.VISA:
    //         return <VisaIcon />;
    //     default:
    //         return <CreditCardIcon />;
    // }

    // Don't show credit card icon if last4 is not available
    // We don't know what other payment methods are available, so we will just not show any icon
    if (!paymentMethod.last4) {
        return null;
    }

    return <CreditCardIcon />;
};

export type IPaymentMethod = {
    id: string;
    type: EPaymentMethodType;
    lastFour: string;
    expirationMonth: number;
    expirationYear: number;
    isDefault: boolean;
};
