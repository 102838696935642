import React, { lazy, Suspense, useEffect } from 'react';
import Snowfall from 'react-snowfall';
import { Route, useLocation, Navigate, Routes } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';

import { useTrackPageViews } from 'src/services/analytics';
import { landlordAuthRoutes, landlordProtectedRoutes, LANDLORD_HOME_PATH } from 'src/routes';
import { ThemeProvider } from 'src/theme';
import {useSnowfall} from 'src/useSnowfall';

const LandlordLayout = lazy(() => import('src/components/layout/landlord/LandlordLayout'));
const OnboardingPage = lazy(() => import('src/pages/landlord/onboard/OnboardingPage'));
const AuthenticatedContainer = lazy(() => import('src/components/authenticated-container'));
const NotFoundPage = lazy(() => import('src/pages/not-found/NotFound'));

const useSuspenseFallback = () => {
    const { pathname } = useLocation();

    if (pathname.startsWith('/landlord/inner')) {
        return <LandlordLayout isLoading={true} />;
    } else {
        return (
            <ThemeProvider>
                <LinearProgress />
            </ThemeProvider>
        );
    }
};

export default function App() {
    const { pathname } = useLocation();
    const fallback = useSuspenseFallback();

    // Scroll to top on route change
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useTrackPageViews();

    const { isSnowing } = useSnowfall();

    return (
        <>
            {/* eslint-disable-next-line no-inline-styles/no-inline-styles */}
            {isSnowing && <Snowfall style={{zIndex: '9999'}} />}
            <Suspense fallback={fallback}>
                <Routes>
                    <Route path="/" element={<Navigate to={LANDLORD_HOME_PATH} />} />
                    <Route path="/landlord" element={<Navigate to={LANDLORD_HOME_PATH} />} />
                    <Route path="/landlord/inner" element={<Navigate to={LANDLORD_HOME_PATH} />} />

                    {/* TODO: Put all landlord auth routes under `/landlord/auth` */}
                    {...landlordAuthRoutes}

                    <Route path="/landlord/onboard/*" element={<OnboardingPage />} />

                    <Route path="/landlord/inner" element={<AuthenticatedContainer />}>
                        {...landlordProtectedRoutes}
                    </Route>

                    <Route path="*" element={<NotFoundPage />} />
                </Routes>
            </Suspense>
        </>
    );
}
