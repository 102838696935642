import {useEffect, useState} from 'react';

export const useSnowfall = () => {
    // Pretty funny temp solution, just because we can do it instead of annoying context for that one week of snowfall
    // In case it's needed anywhere else except App.tsx we do not need to be in context
    // instead it's kind of old style old school way
    // TODO remove this after snowfall week

    const [isSnowing, setIsSnowing] = useState(true);

    useEffect(() => {
        try {
            setIsSnowing(localStorage.getItem('snowfall_enabled') != 'false');
        } catch (e) {
            localStorage.setItem('snowfall_enabled', 'true');
        }
    }, []);

    const withLocalStorageAndEvent = (v: boolean) => {
        localStorage.setItem('snowfall_enabled', v.toString());
        window.dispatchEvent(new Event('snowfall-enabled'));
        setIsSnowing(v);
    };

    const cb = () => {
        setIsSnowing(localStorage.getItem('snowfall_enabled') != 'false');
    };

    useEffect(() => {
        window.addEventListener('snowfall-enabled', cb);

        return () => {
            window.removeEventListener('snowfall-enabled', cb);
        };
    }, []);

    return {
        isSnowing,
        setIsSnowing: withLocalStorageAndEvent
    };
};
