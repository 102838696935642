import { useQuery, } from 'react-query';

import { type IPayment } from 'src/services/api/payments/types';
import { EPaymentMethodType } from 'src/services/api/payment-methods/types';
import { queryKeys } from 'src/services/api/constants';
// import { GET_PAYMENTS_PATH } from 'src/services/api/payments/constants';
// import { apiClient } from 'src/services/api';

const payments: IPayment[] = [
    {
        id: '1',
        createdAt: '2024-01-01T20:00:00Z',
        plan: 'Basic',
        paymentMethod: {
            type: EPaymentMethodType.VISA,
            lastFour: '1234',
        },
        amount: 100,
    },
    {
        id: '2',
        createdAt: '2024-02-01T20:00:00Z',
        plan: 'Basic',
        paymentMethod: {
            type: EPaymentMethodType.MASTERCARD,
            lastFour: '5678',
        },
        amount: 100,
    },
    {
        id: '3',
        createdAt: '2024-03-01T20:00:00Z',
        plan: 'Basic',
        paymentMethod: {
            type: EPaymentMethodType.AMEX,
            lastFour: '9012',
        },
        amount: 100,
    },
    {
        id: '4',
        createdAt: '2024-04-01T20:00:00Z',
        plan: 'Basic',
        paymentMethod: {
            type: EPaymentMethodType.DISCOVER,
            lastFour: '3456',
        },
        amount: 100,
    },
    {
        id: '5',
        createdAt: '2024-05-01T20:00:00Z',
        plan: 'Basic',
        paymentMethod: {
            type: EPaymentMethodType.VISA,
            lastFour: '6789',
        },
        amount: 100,
    },
    {
        id: '6',
        createdAt: '2024-06-01T20:00:00Z',
        plan: 'Basic',
        paymentMethod: {
            type: EPaymentMethodType.MASTERCARD,
            lastFour: '2345',
        },
        amount: 100,
    },
    {
        id: '7',
        createdAt: '2024-07-01T20:00:00Z',
        plan: 'Basic',
        paymentMethod: {
            type: EPaymentMethodType.AMEX,
            lastFour: '8901',
        },
        amount: 100,
    },
    {
        id: '8',
        createdAt: '2024-08-01T20:00:00Z',
        plan: 'Basic',
        paymentMethod: {
            type: EPaymentMethodType.DISCOVER,
            lastFour: '4567',
        },
        amount: 100,
    },
    {
        id: '9',
        createdAt: '2024-09-01T20:00:00Z',
        plan: 'Basic',
        paymentMethod: {
            type: EPaymentMethodType.VISA,
            lastFour: '7890',
        },
        amount: 100,
    },
    {
        id: '10',
        createdAt: '2024-10-01T20:00:00Z',
        plan: 'Basic',
        paymentMethod: {
            type: EPaymentMethodType.MASTERCARD,
            lastFour: '3456',
        },
        amount: 100,
    },
];

export type IGetPaymentsResponse = {
  payments: IPayment[];
};

export const usePayments = () => {
    return useQuery(
        [queryKeys.payments],
        async () => {
            // const res = await apiClient(GET_PAYMENTS_PATH).json<IGetPaymentsResponse>();
            // return res.payments;
            return payments;
        }
    );
};
