import { useMemo } from 'react';
import { blue, orange, red } from '@mui/material/colors';
import { createTheme, type PaletteColor } from '@mui/material/styles';

import baseTheme from 'src/theme/base-theme';
import { desktop } from 'src/theme/responsive';

// As defined in /public/styles/global.css
const BODY_Z_INDEX = 100000000000;

declare module '@mui/material/styles' {
    interface TypographyVariants {
        statistic: React.CSSProperties;
        statisticHeading: React.CSSProperties;
        percentage: React.CSSProperties;
        countSource: React.CSSProperties;
        countValue: React.CSSProperties;
        analyticsTabs: React.CSSProperties;
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        statistic?: React.CSSProperties;
        statisticHeading?: React.CSSProperties;
        percentage?: React.CSSProperties;
        countSource?: React.CSSProperties;
        countValue?: React.CSSProperties;
        analyticsTabs?: React.CSSProperties;
    }
  }

  // Update the Typography's variant prop options
  declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
      /**
       * value for each heading in analytics banner
       */
      statistic: true;
      /**
       * headings for analytics banner
       */
      statisticHeading: true;
      /**
       * computed percentage change (compared to previous week)
       */
      percentage: true;
      countSource: true;
      countValue: true;
      analyticsTabs: true;
    }
}

// The color palette here has to match the colors defined in /public/styles/variables.css.
// The reason we cannot reuse the colors from the variables.css file is because the MUI theme
// doesn't accept css variables as values for the palette.
export const useTheme = (isTawkChatOpened: boolean) => {
    return useMemo(() => {
        const zIndexBase = isTawkChatOpened ? 0 : BODY_Z_INDEX;

        return createTheme({
            palette: {
                ...baseTheme.palette,
                primary: {
                    50: '#e2f7f0',
                    100: '#b7ead9',
                    200: '#88dcc0',
                    300: '#58cea7',
                    400: '#34c494',
                    500: '#10b981',
                    600: '#0eb279',
                    700: '#0caa6e',
                    800: '#09a264',
                    900: '#059351',
                    light: '#88dcc0',
                    main: '#10b981',
                    dark: '#09a264',
                },
                success: {
                    50: '#e6efe6',
                    100: '#c0d8c2',
                    200: '#97be99',
                    300: '#6da470',
                    400: '#4d9151',
                    500: '#2e7d32',
                    600: '#29752d',
                    700: '#236a26',
                    800: '#1d601f',
                    900: '#124d13',
                    light: '#97be99',
                    main: '#2e7d32',
                    dark: '#1d601f',
                },
                info: blue,
                warning: orange,
                error: red,
                dark: {
                    light: '#646464',
                    main: '#212121',
                    dark: '#141414',
                    contrastText: '#fafafa'
                },
                background: {
                    default: 'var(--background-color-beige)',
                }
            },
            typography: {
                fontFamily: 'Inter, sans-serif',
                allVariants: {
                    fontWeight: 400,
                    letterSpacing: '-0.3px',
                    color: baseTheme.palette.grey[900],
                },
                h1: {
                    fontFamily: 'Outfit, sans-serif',
                    fontSize: '2rem',
                    fontWeight: 300,

                    [desktop]: {
                        fontSize: '2.5rem',
                    }
                },
                h2: {
                    fontFamily: 'Outfit, sans-serif',
                    fontSize: '1.75rem',
                    fontWeight: 300,

                    [desktop]: {
                        fontSize: '2rem',
                    }
                },
                h3: {
                    fontFamily: 'Outfit, sans-serif',
                    fontSize: '1.5rem',
                    fontWeight: 300,

                    [desktop]: {
                        fontSize: '1.5rem',
                    }
                },
                h4: {
                    fontFamily: 'Outfit, sans-serif',
                    fontSize: '1.25rem',
                    fontWeight: 300,

                    [desktop]: {
                        fontSize: '1.25rem',
                    }
                },
                h5: {
                    fontFamily: 'Outfit, sans-serif',
                    fontSize: '1.15rem',
                    fontWeight: 300,

                    [desktop]: {
                        fontSize: '1.15rem',
                    }
                },
                /**
         * Custom variants for the analytics page
         */
                statistic: {
                    color: 'white',
                    fontWeight: 400,
                    [desktop] :{
                        fontSize: '20px',
                    }
                },
                statisticHeading: {
                    color: 'white',
                    fontSize: '10px',
                    [desktop]: {
                        fontSize: '14px',
                    }
                },
                percentage: {
                    color: 'white',
                    fontSize: '18px',
                    opacity: 0.7,
                },
                countSource: {
                    fontSize: '14px',
                    fontWeight: 500,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                },
                countValue: {
                    fontSize: '14px',
                    fontWeight: 700,
                },
                analyticsTabs: {
                    padding: '16px 0px',
                    fontSize: '16px',
                    fontWeight: 600,

                }
            },
            zIndex: {
                // Default MUI z-index values
                // https://mui.com/material-ui/customization/z-index/
                // mobile stepper: 1000,
                // fab: 1050,
                // speed dial: 1050,
                // app bar: 1100,
                // drawer: 1200,
                // modal: 1300,
                // snackbar: 1400,
                // tooltip: 1500,

                // Set z index value as the z index of the body element + default MUI z index value
                mobileStepper: zIndexBase + 1000,
                fab: zIndexBase + 1050,
                speedDial: zIndexBase + 1050,
                appBar: zIndexBase + 1100,
                drawer: zIndexBase + 1200,
                modal: zIndexBase + 1300,
                snackbar: zIndexBase + 1400,
                tooltip: zIndexBase + 1500,
            },
            components: {
                MuiButtonBase: {
                    defaultProps: {
                        disableRipple: true,
                    },
                },
                MuiButton: {
                    styleOverrides: {
                        root: {
                            textTransform: 'none',
                            fontWeight: 600,
                            boxShadow: 'none !important',
                        },
                        containedPrimary: {
                            color: '#fff',
                        }
                    }
                },
                MuiIconButton: {
                    styleOverrides: {
                        root: {
                            ':hover': {
                                backgroundColor: 'unset'
                            }
                        }
                    }
                },
                MuiTabs: {
                    styleOverrides: {
                        root: {
                            minHeight: 0,
                        },
                        indicator: {
                            height: 3,
                        }
                    }
                },
                MuiTab: {
                    styleOverrides: {
                        root: {
                            minHeight: 36,
                            padding: '12px 16px',
                            textTransform: 'none',
                        }
                    }
                },
                MuiSvgIcon: {
                    styleOverrides: {
                        fontSizeSmall: {
                            width: 14,
                            height: 14
                        }
                    }
                },
                MuiChip: {
                    styleOverrides: {
                        sizeSmall: {
                            height: 20,
                            padding: '0 0.5rem',
                            fontSize: '0.75rem',
                        },
                        labelSmall: {
                            paddingLeft: 0,
                            paddingRight: 0,
                        }
                    }
                },
                MuiLink: {
                    styleOverrides: {
                        root: {
                            textDecoration: 'none',
                        }
                    }
                },
                MuiStepIcon: {
                    styleOverrides: {
                        text: {
                            fill: '#fff',
                        }
                    }
                },
                MuiTypography: {
                    defaultProps: {
                        variantMapping: {
                            statistic: 'div'
                        }
                    }
                },
                MuiLinearProgress: {
                    styleOverrides: {
                        root: {
                            width: '100%',
                        }
                    }
                },
            }
        });
    }, [isTawkChatOpened]);
};

declare module '@mui/material/styles' {
    interface Palette {
        dark: PaletteColor;
    }
    interface PaletteOptions {
        dark: PaletteColor;
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        dark: true;
    }
}
